<template>
  <div>
    <b-modal
      @hidden="resetModal"
      ref="modal-extra-info"
      centered
      size="lg"
      hide-footer
      hide-header
      content-class="overflow-hidden"
      >
      <div class="row align-items-center">
        <div class="col-12 text-white border-bottom mb-3" >
          <h5 class="text-dark mb-3"><i class="uil uil-comment-alt-notes text-primary"></i> {{extraInfo.title}}</h5>
        </div>
        <div class="col-12 text-muted overflow-auto">
          <div class="overflow-auto">
            <div v-if="extraInfo.info.logs">
              <pre>{{extraInfo.info.logs}}</pre>
            </div>
          </div>
          <div class="overflow-auto">
            <div v-if="extraInfo.info.pg_callback">
              <pre>{{extraInfo.info.pg_callback}}</pre>
            </div>
          </div>
          <div v-if="extraInfo.info.topup_id">
              <div>
                <div class="row">
                <div class="col-lg-6">
                    <div class="card border">
                      <div class="card-body py-2">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-user font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-14"> #{{  extraInfo.info.merchant_id }}</small>
                                      <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_name }}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body py-2 border-top">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-book font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-13 text-muted d-block mb-1"> Contract Detail</small>
                                      <h5 class="font-size-15 mb-0"> {{  extraInfo.info.contract_id }}: {{  extraInfo.info.contract_name }}</h5>
                                      <div class="font-size-13 mb-1">Currency: <span class="text-primary fw-medium">{{  extraInfo.info.contract_currency }}</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card border">
                    <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Sender's Bank/Crypto</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.sender_bank }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account Name/Network</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.sender_account_name }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account No./Wallet Address</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.sender_account_number }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Selected Currency for Top Up</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.recharge_currency }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Currency Rate</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.currency_rate }}</h5>
                          </div>
                          <div>
                            <small class="font-size-13 mb-1 d-block">Transaction Remarks</small>
                            <textarea rows="5" class="form-control text-muted w-100" v-model="extraInfo.info.transaction_remarks" readonly></textarea>
                          </div>
                      </div>
                   </div>
                </div>
                <div class="col-lg-6">
                  <div class="card border">
                        <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Top Up ID</small>
                          <h5 class="font-size-15 mb-1"> #{{ extraInfo.info.topup_id }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Top Up Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{ convertCurrencyFormat(extraInfo.info.topup_amount,true) }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Total Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{  convertCurrencyFormat(extraInfo.info.payin_total_charges,true) }} <small class="fw-normal text-muted">({{  extraInfo.info.payin_rate }}% +
                              {{  extraInfo.info.contract_currency }} {{  extraInfo.info.payin_extra_charges}})</small></h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Merchant Receives Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{  convertCurrencyFormat(extraInfo.info.merchant_received_amount,true) }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Company Receives Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.recharge_currency }} {{  convertCurrencyFormat(extraInfo.info.company_received_amount,true) }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction ID</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.transaction_id }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction Receipt(s)</small>
                            <div class="font-size-14 mb-1" v-if="extraInfo.info.transfer_receipts">
                              <span v-for="(v, i) in extraInfo.info.transfer_receipts" :key="i" class="d-block">
                              <a
                                  v-if="v !==''"
                                  :href="v"
                                  target="_blank"
                                  title="View Receipt"
                                  ><i
                                    class="uil uil-receipt-alt font-size-16 text-primary"
                                  ></i>
                                  View Receipt ({{ i+1 }})</a
                                >
                              </span>
                            </div>
                          </div>
                          <div class="mb-3">
                            <small class="font-size-13">Status</small>
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'complete'">
                              <span class="badge bg-soft-success fw-medium font-size-14">Completed</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'rejected'">
                              <span class="badge bg-soft-danger fw-medium font-size-14">Rejected</span>
                            </h5>
                            <textarea v-if="extraInfo.info.status == 'rejected'" rows="3" class="text-muted form-control" v-model="extraInfo.info.reason" readonly></textarea>
                          </div>
                          <hr>
                          <div class="font-size-12">Created Date: {{ convertDateByCountry(extraInfo.info.created_at) }}</div>
                          <div class="font-size-12">Updated Date: {{ convertDateByCountry(extraInfo.info.updated_at) }}</div>
                          <div class="mt-2 font-size-12" v-if="account_type=='admin'">Processed By {{   extraInfo.info.processed_by }}</div>
                        </div>
                   </div>
                </div>
                <div class="col-12">  
                    
                </div>
              
              </div>
            </div>
          </div>
          <div v-if="extraInfo.info.transaction_remark"> {{ extraInfo.info.transaction_remark}}</div>
          <div v-if="extraInfo.info.transaction_remarks && !extraInfo.info.topup_id"> {{ extraInfo.info.transaction_remarks}}</div>
          <div v-if="extraInfo.info.transfer_remarks"> {{ extraInfo.info.transfer_remarks}}</div>
          <div class="d-grid gap-2 mt-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-extra-info'].hide()">Cancel</button>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import Common from "@/components/common";
  export default {
    components: {
      Common
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      timezone: {
        type: String,
        required: false,
      }
    },
    data() {
      return {
        selectedTimezone: "Malaysia",
        timezoneCountry: appConfig.timezoneCountryList,
        extraInfo:{title: "", info: {}},
        account_type:""
      };
    },
    middleware: "authentication",
    async mounted() {
      await this.reload();
    },
    created() {},
    methods: {
      showExtraInfoModal() {
        this.$refs['modal-extra-info'].show()
        this.extraInfo = this.data.extraInfo;
      },
      convertDateByCountry(dateTimeString) {
        if (dateTimeString==null){
          return "-"
        }else{
          return this.$refs.commonFunc.convertDateByCountry(dateTimeString, this.timezone);
        }
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      resetModal() {
        this.extraInfo = {title: "", info: {}}
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            this.selectedTimezone = data.time_zone;
            console.log(data)
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>